.dropdown {
  border-bottom: 1px solid #F3F3F3;

  .dropdownHeader {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    height: 63px;
    gap: 10px;

    &.dropdownContentOpen {
      background-color: #2b6cde33;

      .dropdownLabel {
        color: #083667;
      }

      .iconOpen {
        path {
          fill: #083667;
        }
      }
    }

    .dropdownIcon:hover {


    }

    .dropdownLabel {
      font-size: 0.875rem;
      color: #656565;
    }

    &:hover {
      span {
        color: #083667;

        path {
          fill: #213c61;
        }
      }
    }
  }

  .dropdownContent {
    padding: 0;

    .dropdownItem {
      height: 40px;
      list-style-type: none;
      cursor: pointer;
      font-size: 0.875rem;
      color: #083667;

      &:hover {
        color: #073157;
        background-color: #2b6cde33;
      }

      & > li {
        height: 100%;
        padding: 10px 0 10px 40px;
        transition: padding 0.3s ease, background-color 0.3s ease, color 0.3s ease;
        &:hover {
          padding: 10px 0 10px 50px;
        }
      }

    }
  }
}
