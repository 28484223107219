.dateRangePicker {
  display: flex;
  align-items: center;
  gap: 14px;

  input {
    min-width: 245px;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    font-size: 0.875rem;
    color: #656565;
    outline: none;

    @media(max-width: 1495px){
      min-width: auto;
    }
  }

  span {
    font-size: 0.875rem;
    color: #656565;
  }
}