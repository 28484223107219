.container {
  min-height: 100vh;

  h1 {
    font-weight: 500;
    font-size: 1.75rem;
    color: #1E1E1E;
  }

  .searchProducts {
    width: 100%;
    height: 142px;
    background-color: #FFFFFF;
    margin-top: 20px;
    padding: 16px;
    border-radius: 10px;

    @media(max-width: 1060px) {
      height: auto;
    }

    .alignInput {
      display: flex;
      align-items: center;
      gap: 25px;

      @media(max-width: 1060px) {
        flex-direction: column;
      }

      .error {
        color: red;
        font-size: 0.75rem;
        width: 300px;
      }

      input {
        width: 100%;
        height: 37px;
        outline: 0;
        border: 1px solid #C5C5C5;
        border-radius: 6px;
        padding: 10px;

        &::placeholder {
          color: #C5C5C5;
        }
      }
    }

    .alignButtonSearch {
      margin-top: 35px;
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .alignButtonCsvRegister {
    display: flex;
    margin: 20px 16px;
    align-items: center;
    justify-content: space-between;
  }

  .groupCount {
    background: white;

    margin-bottom: 15px;
    padding: 10px;
    border-radius: 8px;
    justify-content: center;

    .alignCount {
      display: flex;
      flex-direction: column;
      gap: 6px;

      p {
        margin: 0;
        border-bottom: 1px solid #F3F3F3;
        font-size: 0.95rem;
        font-weight: 500;
      }

      span {
        font-size: 0.95rem;
      }

    }
  }

  .tableProducts {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 555px;
    min-height: 555px;

    table {
      width: 100%;
      border-collapse: collapse;

    }

    th, td {
      padding: 8px;
      text-align: left;
      border-bottom: 1px solid #F3F3F3;
      font-size: 0.75rem;
      font-size: 0.9rem;

    }

    .deleteEmail{
      border: none;
      background: none;
      padding: 4px;
    }

    .editEmail {
      border: none;
      background: none;

      path:hover {
        fill: #2b6cde;
      }
    }

    //.alignEnd {
    //  display: flex;
    //
    //}

    td {
      max-width: 131px;
      height: 70px;
      color: #656565;

      .iconButton {
        border-radius: 8px;
        padding: 10px;
      }

      img {
        max-width: 47px;
        width: 100%;
        height: 64px;
      }

      &.alignTd {
        padding: 0;
        padding-right: 48px;
        text-align: center;
      }
    }
  }

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;

    .modalContent {
      background-color: #fff;
      border-radius: 20px;
      padding: 20px;
      width: 500px;
      position: relative;

      .close {
        position: absolute;
        right: 20px;
        font-size: 1.4rem;
        cursor: pointer;
      }

      h2 {
        text-align: center;
        font-size: 1.6rem;
        margin-top: 15px;
      }

      .alignInput {
        display: grid;
        align-items: center;

        .error {
          color: red;
          font-size: 0.75rem;
          width: 300px;
        }

        label {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 16.41px;
          margin-bottom: 5px;
          color: #656565;
        }

        input {
          width: 100%;
          height: 37px;
          outline: 0;
          border: 1px solid #C5C5C5;
          border-radius: 6px;
          padding: 10px;

          &::placeholder {
            color: #C5C5C5;
          }
        }
      }

      .alignButton {
        width: 100%;
        button {
        margin-top: 10px;
        width: 100%;
        }

      }

      .formModal {
        display: grid;
        gap: 10px;
      }
    }
  }
}

.alignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.dNone {
  display: none;
}