.container {
  min-height: 100vh;
  h1 {
    font-weight: 500;
    font-size: 1.75rem;
    color: #1E1E1E;
  }

  .searchProducts {
    width: 100%;
    height: 142px;
    background-color: #FFFFFF;
    margin-top: 20px;
    padding: 16px;
    border-radius: 10px;

    @media(max-width: 1060px) {
      height: auto;
    }

    .alignInput {
      display: flex;
      align-items: center;
      gap: 25px;

      @media(max-width: 1060px) {
        flex-direction: column;
      }

      .error {
        color: red;
        font-size: 0.75rem;
        width: 300px;
      }

      input {
        width: 100%;
        height: 37px;
        outline: 0;
        border: 1px solid #C5C5C5;
        border-radius: 6px;
        padding: 10px;

        &::placeholder {
          color: #C5C5C5;
        }
      }
    }

    .alignButtonSearch {
      margin-top: 5px;
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .alignButtonCsvRegister {
    display: flex;
    margin: 20px 16px;
    align-items: center;
    justify-content: space-between;
  }

  .groupCount {
    display: flex;
    align-items: center;
    gap: 24px;
    background: white;
    margin-bottom: 15px;
    padding: 10px 25px;
    border-radius: 8px;
    width: 100%;
    max-width: 220px;
    justify-content: left;

    .alignCount {
      display: flex;
      flex-direction: column;
      gap: 5px;

      p {
        border-bottom: 1px solid #F3F3F3;
        font-size: 14px;
        padding: 6px 0;
        font-weight: 500;
      }

      span {

      }
    }
  }

  .tableProducts {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 555px;
    min-height: 555px;

    table {
      width: 100%;
      border-collapse: collapse;
    }

    th, td {
      padding: 8px;
      text-align: left;
      border-bottom: 1px solid #F3F3F3;
      font-size: 0.75rem;
    }

    //.alignEnd {
    //  display: flex;
    //
    //}

    td {
      max-width: 131px;
      height: 70px;
      color: #656565;
      text-transform: capitalize;

      &.actions {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }

      .iconButton {
        border-radius: 8px;
        padding: 10px;
      }

      img {
        max-width: 47px;
        width: 100%;
        height: 64px;
        object-fit: cover;
      }

      &.alignTd {
        padding: 0;
        padding-right: 48px;
        text-align: center;
      }
    }
  }
}

.alignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.dNone {
  display: none;
}

.select {
  width: 300px;
  margin-top: 10px;
}

.message {
  text-align: center;
}



