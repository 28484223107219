.alignPagination {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.pagination {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
  max-width: 173px;
}

.page {
  padding: 10px 17px;
  width: 43px;
  height: 38px;
  cursor: pointer;
  color: #333;
}

.page.active {
  background-color: #083667 !important;
  color: #fff;
}

.page:hover {
  background-color: #f0f0f0;
}