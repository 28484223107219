.sidebar {
  width: 314px;
  background-color: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  transition: width 0.3s;
  z-index: 1000;

  @media (max-width: 768px) {
    background-color: transparent;
  }

  &.collapsed {
    width: 60px;

    .sidebarHeader {
      padding: 0;

      .svgLogo {
        display: none;
      }

      p, button {
        display: none;
      }

      .menuButton {
        display: flex;
      }
    }

    .sidebarMenu {
      .controlPanel {
        display: none;
      }
    }

    .DropdownClose {
      display: none;
    }

  }

  .sidebarHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0 10px 0;
    position: relative;
    width: 100%;

    .alignTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 50px;

      .sidebarTitle {
        font-size: 44px;
        font-weight: 600;
        color: #333;
        line-height: 38px;

        span {
          color: #2b6cde;
        }
      }

      .logo {
        width: 100%;
        height: 150px;

        @media(max-width: 900px) {
          height: 140px;
        }
      }
    }

    p {
      font-size: 0.875rem;
    }

    .svgLogo {
      width: 200px;
      height: 55px;
    }

    .menuButton {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      cursor: pointer;
      z-index: 1100;
      outline: 0;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .sidebarMenu {
    list-style-type: none;
    padding: 0;
    border-top: 1px solid #F3F3F3;

    .controlPanel {
      display: flex;
      align-items: center;
      height: 63px;
      border-bottom: 1px solid #F3F3F3;
      padding: 10px;
      gap: 10px;
      color: #656565;
      font-size: 0.875rem;
      cursor: pointer;

      span {
        margin-left: 10px;
      }
    }

    .menuItem {
      margin: 10px 0;

      .submenu {
        list-style-type: none;
        padding-left: 20px;
      }
    }
  }
}

.mainContainer {
  margin: 0 0 0 314px;
  background-color: #FAFAFA;
  transition: all 0.4s ease;
  position: relative;
  min-height: 100vh;
  padding: 23px 36px 5px 26px;
  min-width: 320px;


  &.expanded {
    margin-left: 80px;

    @media (max-width: 768px) {
      margin-left: 0;
      padding: 25px;
    }
  }

  //@media(max-width: 1150px) {
  //  display: flex;
  //  justify-content: center;
  //  padding: 115px 0;
  //  margin-bottom: 100px;
  //}
}
