.background {
  .container {
    border-radius: 6px;

    .title {
      display: flex;
      align-items: center;
      gap: 24px;

      h1 {
        font-size: 1.75rem;
        font-weight: 600;
        line-height: 32.81px;
      }

      .btnReturn {
        display: flex;
        width: 115px;
        height: 25px;
        padding: 4px 25.5px 5px 25.5px;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        color: #656565;
        border: 1px solid #c5c5c5;
        text-decoration: none;
      }

      margin-bottom: 30px;
    }

    p {
      color: #3f3f3f;
    }

    .searchContainer {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 50px;

      @media (max-width: 480px) {
        flex-direction: column;
      }

      .skuInput {
        width: 320px;
        height: 48px;
        padding: 12px 16px;
        border-radius: 6px;
        border: 1px solid #c5c5c5;

        &::placeholder {
          font-size: 1rem;
          font-weight: 400;
          line-height: 21.1px;
          color: #cdcdcd;
        }
      }
    }

    .itemContainer {
      margin-bottom: 30px;
      border-radius: 10px;
      padding: 20px;
      background-color: #ffffff;

      h3 {
        color: #1e1e1e;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 7px;
        margin-bottom: 10px;
      }

      .groupData {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding-top: 10px;
        gap: 15px 30px;

        @media (max-width: 1400px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 1150px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 920px) {
          grid-template-columns: 1fr;
        }

        .groupInput {
          display: grid;

          label {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 16.41px;
            margin-bottom: 5px;
            color: #656565;
          }

          input {
            height: 37px;
            padding: 10px;
            border-radius: 6px;
            border: 1px solid #c5c5c5;
            font-family: 'Roboto', sans-serif;

          }

        }

        .groupInput.textArea {
          grid-column: 1 / -1;

          .descriptionWrapper {
            display: grid;
            width: 100%;

            .description {
              overflow: hidden;
              transition: max-height 0.3s ease-out;
              padding: 10px;
              border-radius: 6px;
              border: 1px solid #c5c5c5;
              font-family: 'Roboto', sans-serif;
              overflow: hidden;

              &.collapsed {
                max-height: 200px;
              }
            }

            .expandButton {
              margin-top: 10px;
              font-size: .9rem;
              border: none;
              padding: 6px 12px;
              border-radius: 8px;
              justify-self: center;

              &:hover {
                color: #4c4c4c;
              }
            }
          }
        }
      }

      .materia {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 30px;
        align-items: center;
        padding: 12px 0;
        border-bottom: 1px solid #f3f3f3;

        @media (max-width: 1250px) {
          display: grid;
          grid-template-columns: 300px auto;
          justify-content: flex-start;

          svg {
            grid-column: 1 / -1;
          }
        }

        @media (max-width: 900px) {
          display: grid;
          grid-template-columns: auto;
        }

        &:last-child {
          border-bottom: none;
        }

        a {
          max-width: 50ch;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-decoration: underline;
          cursor: pointer;
          color: #2b6cde;
        }

        button {
          font-size: 0.8rem;
        }

        .groupInput {
          display: grid;

          label {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 16.41px;
            margin-bottom: 5px;
            color: #656565;
          }

          input {
            height: 37px;
            padding: 12px 16px;
            border-radius: 6px;
            border: 1px solid #c5c5c5;
          }

          input[type="text"] {
            width: 300px;

            @media (max-width: 900px) {
              width: 200px;
            }
          }

          input[type="number"] {
            width: 100px;
          }
        }
      }

      .sumario {
        display: flex;
        align-items: center;
        gap: 20px;
        // padding: 12px 0;

        a {
          max-width: 50ch;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-decoration: underline;
          cursor: pointer;
          color: #2b6cde;
        }

        button {
          font-size: 0.8rem;
        }
      }

      .capa {
        display: flex;
        gap: 20px;
        padding: 12px 0;
        align-items: center;

        a {
          max-width: 50ch;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p {
          margin: 0;
        }

        .file {
          padding: 10px;
          font-size: 1rem;
          font-weight: 600;
          transition: all 0.1s;
          border: none;
          border-radius: 8px;
          outline: 0;
          cursor: pointer;
          background-color: var(--primary-color);
          color: #ffffff;
          position: relative;

          input {
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
            position: absolute;
            inset: 0;
          }
        }
      }
    }

    .saveButton button {
      margin-left: 20px;
      margin-bottom: 20px;

    }
  }
}
