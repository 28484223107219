.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.1s;
  border: none;
  border-radius: 8px;
  outline: 0;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
      transform: none;
    }
  }


  .icon {
    margin-right: 8px;
  }

  &:hover {
    box-shadow: 0px 4px 8px 2px rgba(43, 108, 222, 0.15);
    transform: scale(1.01);
  }


}

.primary {
  
  background-color: var(--primary-color);
  color: #ffffff;

}

.secondary {
  background-color: rgba(43, 108, 222, 0.25);
  color: #2b6cde;
  border: 1px solid #2b6cde;
}

.danger {
  background-color: #fffbfb;
  color: #d83c3c;
  border: 1px solid #d83c3c;
}

.close {
  background-color: #FFFFFF;
  color: #656565;
  border: 1px solid #C5C5C5;
}

.statusButton {
  padding: 4px 8px;
  font-size: .8rem;
  border-radius: 8px;
  width: 80px;
  border: 1px solid transparent;
  outline: none;
}

.sent {
  background-color: #ecffee;
  border-color: #0a9514;
  color: #0a9514;
}

.error {
  background-color: #ffeded;
  border-color: #af3434;
  color: #af3434;
}

.pending {
  background-color: #fffbec;
  border-color: #bda016;
  color: #bda016;
}
